import * as THREE from 'three';
import {OrbitControls} from 'three/examples/jsm/controls/OrbitControls.js';

import starsTexture from '../img/stars.jpg';
import sunTexture from '../img/sun.jpg';
import mercuryTexture from '../img/mercury.jpg';
import venusTexture from '../img/venus.jpg';
import earthTexture from '../img/earth.jpg';
import marsTexture from '../img/mars.jpg';
import jupiterTexture from '../img/jupiter.jpg';
import saturnTexture from '../img/saturn.jpg';
import saturnRingTexture from '../img/saturn ring.png';
import uranusTexture from '../img/uranus.jpg';
import uranusRingTexture from '../img/uranus ring.png';
import neptuneTexture from '../img/neptune.jpg';
import plutoTexture from '../img/pluto.jpg';

const renderer = new THREE.WebGLRenderer();

renderer.setSize(window.innerWidth, window.innerHeight);

document.body.appendChild(renderer.domElement);

const scene = new THREE.Scene();

const camera = new THREE.PerspectiveCamera(
    45,
    window.innerWidth / window.innerHeight,
    0.1,
    1000
);

const orbit = new OrbitControls(camera, renderer.domElement);

camera.position.set(-90, 140, 140);
orbit.update();

const ambientLight = new THREE.AmbientLight(0x333333);
scene.add(ambientLight);

const cubeTextureLoader = new THREE.CubeTextureLoader();
scene.background = cubeTextureLoader.load([
    starsTexture,
    starsTexture,
    starsTexture,
    starsTexture,
    starsTexture,
    starsTexture
]);

const textureLoader = new THREE.TextureLoader();

const sunGeo = new THREE.SphereGeometry(16, 30, 30);
const sunMat = new THREE.MeshBasicMaterial({
    map: textureLoader.load(sunTexture)
});
const sun = new THREE.Mesh(sunGeo, sunMat);
scene.add(sun);



const mercury = createPlanete(3.2, mercuryTexture, 28);
const venus = createPlanete(5.8, venusTexture, 44);
const earth = createPlanete(6, earthTexture, 62, 0, {
    radius: 1,
    widthSegments: 30,
    heightSegments: 30,
    texture: plutoTexture
}, 8);


function createPlanete(size, texture, position, ring, sputnik, spos) {
    const geo = new THREE.SphereGeometry(size, 30, 30);
    const mat = new THREE.MeshStandardMaterial({
        map: textureLoader.load(texture)
    });
    const planet = new THREE.Mesh(geo, mat);
    const obj = new THREE.Object3D();
    obj.add(planet);
    if(ring) {
        const ringGeo = new THREE.RingGeometry(
            ring.innerRadius,
            ring.outerRadius,
            32);
        const ringMat = new THREE.MeshBasicMaterial({
            map: textureLoader.load(ring.texture),
            side: THREE.DoubleSide
        });
        const ringMesh = new THREE.Mesh(ringGeo, ringMat);
        obj.add(ringMesh);
        ringMesh.position.x = position;
        ringMesh.rotation.x = -0.5 * Math.PI;
    }
    if(sputnik) {
        const SGeo = new THREE.SphereGeometry(
            sputnik.radius,
            sputnik.widthSegments,
            sputnik.heightSegments,
            32);
        const SMat = new THREE.MeshBasicMaterial({
            map: textureLoader.load(sputnik.texture),
        });
        const SMesh = new THREE.Mesh(SGeo, SMat);
        const SObj = new THREE.Object3D();
        SObj.add(SMesh);
        planet.add(SObj);
        SMesh.position.x = spos;
        SObj.rotation.x = -0.5 * Math.PI;
        SObj.rotation.y = -0.5 * Math.PI;
        SObj.rotation.z = -0.5 * Math.PI;
    }
    scene.add(obj);
    planet.position.x = position;
    return {planet, obj}
}


// const earthGeo = new THREE.SphereGeometry(6, 30, 30);
// const earthMat = new THREE.MeshBasicMaterial({
//     map: textureLoader.load(earthTexture)
// });
// const earth = new THREE.Mesh(earthGeo, earthMat);
//
// const earthObj = new THREE.Object3D();
// earthObj.add(earth);
// scene.add(earthObj);
// earth.position.x = 62;


// const earthSGeo = new THREE.SphereGeometry(1, 30, 30);
// const earthSMat = new THREE.MeshBasicMaterial({
//     map: textureLoader.load(plutoTexture)
// });
// const earthSputnik = new THREE.Mesh(earthSGeo, earthSMat);
//
// const earthSputnikObj = new THREE.Object3D();
// earthSputnikObj.add(earthSputnik);
//
// earth.add(earthSputnikObj);
// earthSputnik.position.x = 10;


const mars = createPlanete(4, marsTexture, 78);
const jupiter = createPlanete(12, jupiterTexture, 100);
const saturn = createPlanete(10, saturnTexture, 138, {
    innerRadius: 10,
    outerRadius: 20,
    texture: saturnRingTexture
});
const uranus = createPlanete(7, uranusTexture, 176, {
    innerRadius: 7,
    outerRadius: 12,
    texture: uranusRingTexture
});
const neptune = createPlanete(7, neptuneTexture, 200);
const pluto = createPlanete(2.8, plutoTexture, 216);

const pointLight = new THREE.PointLight(0xFFFFFF, 2, 300);
scene.add(pointLight);





console.log(earth.planet.children);

function animate() {

    sun.rotateY(0.004);
    mercury.planet.rotateY(0.004);
    venus.planet.rotateY(0.002);
    earth.planet.rotateY(0.002);
    mars.planet.rotateY(0.018);
    jupiter.planet.rotateY(0.04);
    saturn.planet.rotateY(0.038);
    uranus.planet.rotateY(0.03);
    neptune.planet.rotateY(0.032);
    pluto.planet.rotateY(0.008);


    mercury.obj.rotateY(0.004);
    earth.obj.rotateY(0.002);
    venus.obj.rotateY(0.015);
    mars.obj.rotateY(0.008);
    jupiter.obj.rotateY(0.002);
    saturn.obj.rotateY(0.0009);
    uranus.obj.rotateY(0.0004);
    neptune.obj.rotateY(0.0001);
    pluto.obj.rotateY(0.00007);
    earth.planet.children[0].rotateY(-0.02);
    earth.planet.children[0].rotateZ(0.02);


    renderer.render(scene, camera);
}

renderer.setAnimationLoop(animate);

window.addEventListener('resize', function() {
    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();
    renderer.setSize(window.innerWidth, window.innerHeight);
});